export const config = {
    //style: "mapbox://styles/mapbox/streets-v11", //mapbox://styles/mapbox/dark-v10 , mapbox://styles/mapbox/outdoors-v11, mapbox://styles/mapbox/streets-v11, your custom style URL
    style: "mapbox://styles/mapbox/dark-v10", //mapbox://styles/mapbox/dark-v10 , mapbox://styles/mapbox/outdoors-v11, mapbox://styles/mapbox/streets-v11, your custom style URL
    accessToken: "pk.eyJ1IjoibXNsZWUiLCJhIjoiclpiTWV5SSJ9.P_h8r37vD8jpIH1A6i1VRg",
    
    tileset: "./places.geojson",//only needed if using tileset as backend
     
    center: [77.6420, 12.9888], //Lng, Lat for the map center
    pitch: 0, //Deafualt pitch
    zoom: 10, //Default zoom
    title: "blr f&b spots (WIP)",
    description: "something's cooking in this ಉರು",
    sideBarInfo: ["Name"],
    popupTitle: ["Name"],
    popupInfo: ["Summary"],
    filters: [
        {
            type: "checkbox",
            title: "Type",
            columnHeader: "Type",
            listItems: ["cafe", "pub", "restaurant", "ತಿಂಡಿ", "uncategorized"],
        },
        {
            type: "checkbox",
            title: "New",
            columnHeader: "New",
            listItems: ["new", "established"]
        },
        {
            type: "checkbox",
            title: "International",
            columnHeader: "International",
            listItems: ["international", "local"]
        },
    ],
    defaultFilters: [["cafe", "Type"], ["pub", "Type"], ["restaurant", "Type"] , ["ತಿಂಡಿ", "Type"], ["new", "New"]]
//        {
//            type: "dropdown",
//            title: "Title of filter: ",
//            columnHeader: "Column Name",
//            listItems: [
//                'filter one',
//                'filter two',
//                'filter three',
//                'filter four',
//                'filter five',
//                'filter six',
//                'filter seven'
//            ]
//        },
//        {
//            type: "checkbox",
//            title: "Title of filter: ",
//            columnHeader: "Column Name",
//            listItems: ["filter one", "filter two", "filter three"]
//        },
//        {
//            type: "dropdown",
//            title: "Title of filter: ",
//            columnHeader: "Column Name",
//            listItems: [
//                'filter one',
//                'filter two',
//                'filter three',
//                'filter four',
//                'filter five',
//                'filter six',
//                'filter seven'
//            ]
//        }
//    ]

};
